export const PaymentFormsList = [
  "PUE Pago en una sola exhibición",
  // "NA",
];

export const PaymentMethodsList = [
  {
    text: '01 Efectivo',
    value: 'CASH',
    disabled: true
  },
  {
    text: '03 Transferencia electrónica de fondos ( Incluye SPEI)',
    value: 'BANK_TRANSFER',
    disabled: true
  },
  {
    text: '04 Tarjeta de crédito',
    value: 'CREDIT_CARD'
  },
  {
    text: '28 Tarjeta de débito',
    value: 'DEBIT_CARD'
  }
];

export const CFDIList = [
  "P01 Por Definir.",
  "G03 Gastos en general",
  "D01 Honorarios médicos, dentales y gastos hopitalarios.",
  "D02 Gastos médicos por incapacidad o discapacidad.",
  "D04 Donativos.",
  "D05 Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
  "D06 Aportaciones voluntarias al SAR.",
  "D07 Primas por seguros de gastos médicos.",
  "D08 Gastos de transportación escolar obligatoria.",
  "D09 Depósitos en cuenta para el ahorro de primas que tengan como base planes de pensiones.",
  "D10 Pagos por servicios educativos (colegiaturas)",
  "G02 Devoluciones o Descuentos o Bonificaciones",
  "I01 Construcciones.",
  "I02 Mobiliario y equipo de oficina por inversiones.",
  "I03 Equipo de transporte.",
  "I05 Dados, troqueles, moldes, maherramental.",
  "I06 Comunicaciones telefónicas.",
  "I07 Comunicaciones satelitales.",
  "I08 Otra maquinaria y equipo.",
];
